<header class="header">
  <div class="main-nav">
    <div class="navbar-fixed">
      <nav>
        <div class="nav-wrapper">
          <div class="logo-wrap left">
            <a (click)="redirectToPage()" class="brand-logo-ca">
              <img src="https://cdn.centralamerica.com/wp-content/themes/wanderlust/assets/images/logo-ca-2.png"
                alt="Travel Central America"></a>
          </div>
          <ul id="nav-mobile" class="right">
            <li>
              <div>
                <a href="tel:18006089887">
                  <mat-icon class="nav-icon phone">phone</mat-icon><span class="item-description">1-800-608-9887</span>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a (click)="redirectToPage()">
                  <mat-icon class="nav-icon home">home</mat-icon><span class="item-description" >Home</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
